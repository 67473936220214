<template>
  <v-card class="mb-6 mt-3" elevation="0" outlined>
    <containerTitle title="Request Packet"> </containerTitle>
    <v-container>
      <lineFieldsArray
        :fieldAttrRow="{}"
        :fields="fields"
        :templateContent="result"
        :value="pairArray"
        @line-field-input="checkInput(...arguments)"
        :headerTitle="true"
        :addable="true"
        addButtTitle="Add Evaluation"
      ></lineFieldsArray>
    </v-container>
  </v-card>
</template>
<script>
import lineFieldsArray from "@/commonComponents/lineFieldsArray.vue";
import options from "@/cfg/options.json";
import containerTitle from "@/commonComponents/containerTitle.vue";
import lineButtons from "@/cfg/lineButtons.json";
export default {
  props: ["fieldAttributes", "fieldAttrInput", "field", "result","value"],
  components: { lineFieldsArray, containerTitle },
  data() {
    return {
      fields: {
        direction: {
          type: "select",
          // associatedOption: [
          //   ["uplink", "Uplink"],
          //   ["downlink", "Downlink"],
          // ],
          fieldAttrInput: { class: " required" },
          name: "Direction",
          default: "downlink",
          inputFieldFormatter: "iperfEvalDirection",
        },
        operator: {
          type: "select",
          associatedOption: options.numericOperators,
          name: "Operator",
          fieldAttrInput: { class: " required" },
          default: "equal",
        },
        content: {
          type: "text",
          name: "Bits per Second",
          fieldAttrInput: { class: " required" },
        },
        result: {
          type: "select",
          associatedOption: options.ldapResult,
          name: "Result",
          fieldAttrInput: { class: " required" },
          default: "error",
        },
        action: {
          type: "select",
          associatedOption: options.evalAction,
          name: "Action",
          fieldAttrInput: { class: " required" },
          default: "stopTest",
        },
        info: {
          type: "text",
          name: "Info",
          fieldAttrInput: { class: "" ,maxlength:'100' },
        },
        remove: lineButtons.remove,
      },
    };
  },
  computed: {
    pairArray() {
      let pairArray = [];
      if (Array.isArray(this.value)) {
        pairArray = this.value;
      }
      return pairArray;
    },
  },
  methods: {
    checkInput(value) {
      this.$emit("input", value);
    },
  },
};
</script>